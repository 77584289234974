import { z } from 'zod';

import { featureControl } from '@/feature/toggle';
import { commonResponseSchema, datetimeSchema } from '@/services/schemas';

import { getInfoQueryDataSchema } from '../common/utils';

export const rebootComputerKindSchema = z.enum(['restart_streamer', 'normal_reboot', 'safe_mode_reboot', 'shut_down', 'log_off']);

export const computerWindowsUpdatesPolicySchema = z
  .union([
    z.literal('install_automatically'),
    z.literal('only_download'),
    z.literal('only_check'),
    z.literal('never_check'),
    z.literal('system_update_paused'),
  ])
  .nullable();

export const computerWindowsUpdatesStatusSchema = z
  .union([z.literal('ready'), z.literal('updating'), z.literal('installed_wait_restart')])
  .nullable();

export const computerAntivirusStatusSchema = z
  .union([
    z.literal('has_threats'),
    z.literal('no_protection'),
    z.literal('expired'),
    z.literal('no_software'),
    z.literal('not_support_antivirus_version'),
    z.literal('good'),
    z.literal('computer_not_support'),
  ])
  .nullable();

export const computerSharedModeSchema = z.enum([
  'stop_share_server',
  'share_to_all',
  'share_to_someone',
  'share_to_admin',
  'share_to_owner',
]);
export const computerSharedModeMap = computerSharedModeSchema.enum;
export const computerSharedModeNumberEnum = {
  [computerSharedModeMap.stop_share_server]: 0,
  [computerSharedModeMap.share_to_all]: 1,
  [computerSharedModeMap.share_to_someone]: 2,
  [computerSharedModeMap.share_to_admin]: 3,
  [computerSharedModeMap.share_to_owner]: 4,
} as const;

export const computerSharedModeNumberSchema = z.nativeEnum(computerSharedModeNumberEnum);

export const osSchema = z.union([z.literal('win'), z.literal('mac'), z.literal('linux'), z.literal('android'), z.literal('pc')]);
export type OS = z.infer<typeof osSchema>;

export const srsVersionSchema = z.custom<`${number}.${number}.${number}` | `${number}.${number}.${number}.${number}`>((val) =>
  featureControl.getToggle('PCP_1440__Accept_version_in_3_digit')
    ? typeof val === 'string' && val.match(/^\d+\.\d+\.\d+(\.\d+)?$/) !== null
    : typeof val === 'string' && val.match(/^\d+\.\d+\.\d+\.\d+$/) !== null,
);
export type SRSVersion = z.infer<typeof srsVersionSchema>;

export const computerVersionSchema = z.custom<`SRS/${SRSVersion} splashtop2 ${OS}${' RDP' | ' VNC' | ' RDS' | ' SSH' | ''}`>((val) => {
  if (typeof val !== 'string') {
    return false;
  }
  const [srsVersion, splashtop2, os, connectionType, ...rest] = val.split(' ');
  const [srs, version] = srsVersion.split('/');
  if (srs !== 'SRS') {
    return false;
  }
  if (!srsVersionSchema.safeParse(version).success) {
    return false;
  }
  if (splashtop2 !== 'splashtop2') {
    return false;
  }
  if (!osSchema.safeParse(os).success) {
    return false;
  }
  if (connectionType && !['RDP', 'VNC', 'SSH', 'RDS', 'SOS'].includes(connectionType)) {
    return false;
  }
  if (rest.length > 0) {
    return false;
  }
  return true;
});

export const computerDataSchema = z.object({
  id: z.number(),
  uuid: z.string().nullable().optional(),
  name: z.string(),
  version: computerVersionSchema.nullable().optional(),
  group_id: z.number().nullable().optional(),
  group_name: z.string().nullable().optional(),
  online_status: z.boolean().nullable().optional(),
  connected: z.boolean().nullable().optional(),
  /**
   * OS version
   *
   * @example "macOS High Sierra 10.13.6"
   */
  os: z.string().nullable().optional(),
  /**
   * OS product
   *
   * @example "Microsoft Windows 7 Ultimate"
   */
  os_product: z.string().nullable().optional(),
  /**
   * OS build
   *
   * @example Windows: "22H2 (10.0.19042)"
   * @example Mac: "10.13.6"
   */
  os_build: z.string().nullable().optional(),
  /**
   * OS build revision
   *
   * @example "10.0.19042"
   */
  os_build_rev: z.string().nullable().optional(),
  /**
   * OS architecture
   *
   * @example "x86"
   */
  architecture: z.string().nullable().optional(),
  preference_policy: z
    .object({
      name: z.string(),
      id: z.number(),
      maintainable: z.boolean(),
    })
    .optional()
    .nullable(),
  windows_updates: z
    .object({
      important_count: z.number().nullable(),
      optional_count: z.number().nullable(),
      last_updated_at: z.string().nullable(),
      include_software: z.boolean().nullable(),
      reboot_required: z.boolean().nullable(),
      status: computerWindowsUpdatesStatusSchema,
      policy: computerWindowsUpdatesPolicySchema,
      policy_as_assigned: featureControl.getToggle('PCP_1682__Policy_os_patch')
        ? z.boolean().nullable()
        : z.boolean().nullable().optional(),
    })
    .optional()
    .nullable(),
  rds_uuid: z.string().optional().nullable(),
  host_name: z.string().optional().nullable(),
  note: z.string().optional().nullable(),
  support_alert: z.boolean().nullable().optional(),
  support_emm_policy: z.boolean().nullable().optional(),
  support_emm_streamer_policy: z.boolean().nullable().optional(),
  support_emm_patch_policy: z.boolean().nullable().optional(),
  support_emm_os_policy: z.boolean().nullable().optional(),
  support_vulnerability: z.boolean().nullable().optional(),
  support_windows_updates: z.boolean().nullable().optional(),
  support_antivirus: z.boolean().nullable().optional(),
  /** datetime UTC */
  last_online: z.string().optional().nullable(),
  /**
   * IP Address (WAN)
   * @example "1.2.3.4" (IPv4)
   * @example "2001:0db8:85a3:0000:0000:8a2e:0370:7334" (IPv6)
   */
  pubip: z.string().optional().nullable(),
  /**
   * IP Address (LAN)
   * @example "1.2.3.4" (IPv4)
   * @example "2001:0db8:85a3:0000:0000:8a2e:0370:7334" (IPv6)
   */
  local_ip: z.string().optional().nullable(),
  /** datetime UTC */
  last_session: z.string().optional().nullable(),
  is_device_owner: z.boolean().nullable().optional(),
  antivirus: z
    .object({
      enable: z.boolean().nullable(),
      software: z.array(
        z.object({
          name: z.string(),
          id: z.number(),
        }),
      ),
      /** datetime UTC */
      last_scan: z.string().nullable(),
      scan_status: z.union([z.literal('pass'), z.literal('expired'), z.literal('warning')]).nullable(),
      scan_issues: z.number().nullable(),
    })
    .optional()
    .nullable(),
  antivirus_scan_status: computerAntivirusStatusSchema.nullable().optional(),
  antivirus_software: z.string().optional().nullable(),
  antivirus_software_status: z.string().optional().nullable(),
  antivirus_protection: z.string().optional().nullable(),
  antivirus_software_enabled: z.string().optional().nullable(),
  antivirus_software_license: z.string().optional().nullable(),
  antivirus_software_alerts: z.number().optional().nullable(),
  antivirus_policy: z.string().optional().nullable(),
  antivirus_last_scan_time: z.string().optional().nullable(),
  antivirus_scan_issues: z.number().optional().nullable(),
  /** hex string status of each vulnerability check item */
  vulnerability_status: z.string().optional().nullable(),
  alerts_count: z.number().optional().nullable(),
  /** Security layers */
  infra_gen_status: z.string().optional().nullable(),
  unsolved_threats_count: z.number().optional().nullable(),
  last_session_detail: z
    .object({
      end_at: z.string().optional().nullable(),
      start_at: z.string().optional().nullable(),
      user: z.string().optional().nullable(),
    })
    .optional(),
  /** 此電腦擁有者的 email */
  email: z.string().optional().nullable(),
  /** 是否是 deployed computer (也就是 user_id = 0) */
  deployed: z.boolean().optional().nullable(),
  share_mode: computerSharedModeNumberSchema.optional(),
  /** 是否為透過 external_shared 而擁有連線權力的電腦 */
  from_external_shared: z.boolean().optional().nullable(),
});

export const computerDetailSchema = computerDataSchema
  .pick({
    name: true,
    host_name: true,
    email: true,
    version: true,
    online_status: true,
    idle_since: true,
    online_since: true,
    connected: true,
    last_session: true,
    last_online: true,
    pubip: true,
    local_ip: true,
    time_offset: true,
    deployed: true,
    os: true,
    os_product: true,
    os_build: true,
    os_build_rev: true,
    architecture: true,
  })
  .extend({
    online_since: datetimeSchema.optional(),
  });

export const computerDataListSchema = z.array(computerDataSchema);

export const updateStreamerResponseSchema = z.object({});

/**
 * If one of the server_ids is not allowed for current_user, do nothing, and return target_not_allow.
 */
export const updateStreamerNotAllowSchema = commonResponseSchema.merge(
  z.object({
    result: z.literal(40403),
    data: z.object({
      error: z.literal('target_not_allow'),
    }),
  }),
);

/**
 * We still limit to support only 'restart_streamer' and 'normal_reboot' in bulk actions although the API supports all the options
 */
export const batchRebootComputersOptionSchema = rebootComputerKindSchema.extract(['restart_streamer', 'normal_reboot']);
export const batchRebootComputersOptionMap = batchRebootComputersOptionSchema.enum;

export const teamComputersPermissionsSchema = getInfoQueryDataSchema(
  z.object({
    team_member_permissions: z.object({
      member_list: z.boolean(),
    }),
  }),
);
export const computerRemoteControlPermissionsSchema = getInfoQueryDataSchema(
  z.object({ team_permissions: z.object({ rdp_computer: z.boolean(), vnc_computer: z.boolean() }) }),
);
export const userSchema = z.object({ id: z.number(), email: z.string() });

export const computerSharedWithSchema = z.object({
  members: z.array(userSchema),
  users: z.array(userSchema),
});
export const getComputerSharedModeResponseSchema = z.object({ mode: computerSharedModeSchema, share_with: computerSharedWithSchema });

const updateIdListSchema = z.object({ add: z.array(z.number()), remove: z.array(z.number()) }).partial();
export const updateComputerSharedModePayloadSchema = z.discriminatedUnion('mode', [
  z.object({ mode: computerSharedModeSchema.exclude([computerSharedModeMap.share_to_someone]) }),
  z.object({
    mode: z.literal(computerSharedModeMap.share_to_someone),
    /**
     * team members you want to share with(member id). you can only share to someone in your team.
     */
    members: updateIdListSchema,
    /**
     * someone you want to share with(user id).
     * You should only share with someone not in your team.
     * If you share to some in your team, BE will convert it to member-id and handle it.
     *
     * You can get user-id by API User Searchable Info API
     */
    users: updateIdListSchema,
  }),
]);
export const updateComputerSharedModeResponseSchema = z.object({});

export const shareableUserSchema = z.object({
  target_user_id: z.number(),
  target_user_email: z.string(),
  target_member_id: z.null(),
});
export const shareableTeamMemberSchema = shareableUserSchema.extend({
  target_member_id: z.number(),
});
export const checkUserIsShareableResponseSchema = z.union([shareableUserSchema, shareableTeamMemberSchema]);

export const computerListTokenItemSchema = z.string();
export const computerListTokenListSchema = z.array(computerListTokenItemSchema);

/**
 * The target user is in MSP team, which is NOT able to share.
 */
export const targetUserIsMspTeamSchema = commonResponseSchema.merge(
  z.object({
    result: z.literal(41403),
    data: z.object({
      error: z.literal('target_member_not_allow'),
    }),
  }),
);

/**
 * The target user can NOT be shared.
 */
export const targetUserCanNotBeSharedSchema = commonResponseSchema.merge(
  z.object({
    result: z.literal(40416),
    data: z.object({
      error: z.literal('not_allowed_to_do_this'),
    }),
  }),
);

/**
 * The current user can NOT manage the target server.
 */
export const currentUserCanNotManageSchema = commonResponseSchema.merge(
  z.object({
    result: z.literal(40404),
    data: z.object({
      error: z.literal('resource_not_exists'),
    }),
  }),
);

/**
 * The current user can manage but can NOT share the target server.
 */
export const currentUserNotShareableSchema = commonResponseSchema.merge(
  z.object({
    result: z.literal(40403),
    data: z.object({
      error: z.literal('target_not_allow'),
    }),
  }),
);
